.card{
	max-width: 300px;
	width: 100%;
	align-items: flex-start;
	justify-content: flex-start;
	background-image: linear-gradient(90deg, white, transparent);
	padding: 60px 45px;
	height: 230px;
	border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to left, var(--yellow-color), transparent) 1;
  border-radius: 15px;
  cursor: pointer;
  transition: 300ms ease-in-out;
  position: relative;
}
.card:hover{
	border-image: linear-gradient(to left, var(--blue-color), transparent) 1;
}

.service{
	font-size: 1.5rem;
}

.adv{
	color: grey;
	font-size: 1.1rem;
}

.expand{
	margin-left: auto;
	font-size: 1.6rem;
	color: var(--yellow-color);
	  transition: 300ms ease-in-out;
}
.expand:hover{
	color: var(--blue-color);
}


@media screen and (max-width:700px){
	.card{
		padding: 40px 25px;
	}
}

.shape{
	height: 10px;
	width: 10px;
	background-color: var(--yellow-color);
	position: absolute;
	top: 15px;
	left: 15px;
	transform: rotate(45deg);
	border: 1px solid black;
}
.shape2{
	height: 2px;
	width: 100px;
	background-color: var(--yellow-color);
}

.flow{
	position: absolute;
	bottom: 15px;
	left: 45px;
	width: calc(100% - 60px);
}