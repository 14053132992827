.ticker{
	position: relative;
	width: 100%;
}

.ticker::after{
	content:'';
	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background-image: linear-gradient(to left, var(--bg-color) , transparent ,transparent ,transparent ,transparent ,transparent ,transparent ,transparent ,transparent, var(--bg-color) );
}