*{
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: var(--bg-color);
  transition: 250ms ease-in-out;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-base: "DM Sans", sans-serif;

  --bg-color:#fbf4ce;

  --yellow-color:#ffbb00;
  --blue-color:#252aff;
}


::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f5f5f5; 
}

::-webkit-scrollbar-thumb {
  background: var(--yellow-color);
}

::-webkit-scrollbar-thumb:hover {
  background: grey; 
}

.react-multiple-carousel__arrow{
  display: none;
}