.showcase_main{
	position: relative;
	background: white;
	justify-content: flex-start;
	border-radius: 5px;
	height: 80%;
	padding: 15px 0;
	width: 80%;
	border-width: 2px;
    border-style: solid;
    border-image: linear-gradient(to left, var(--yellow-color), transparent) 1;
}
.title_show{
	font-size: 2rem;
	text-transform: uppercase;
	overflow: hidden;
}

.gal_container{
	width: 100%;
	height: 100%;
	flex-wrap: wrap;
}

.gal_img{
	width: 32%;
	height: auto;
	border-radius: 10px;
	box-shadow: 1px 1px 5px -1px black;
}

@media (min-width:700px) and (max-width:1300px){
	.gal_img{
		width: 48%;
	}
	.title{
		height: 250px;
	}
}

@media screen and (max-width:700px){
	.gal_img{
		width: 98%;
	}
	.title{
		height: 250px;
	}
}