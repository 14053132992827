.testicard_main{
	border: 1px solid white;
	border-radius: 8px;
	padding:15px ;
	gap: 5px;
	margin: 5px;
	min-height: 120px;
}
.test_logo{
	height: 40px;
	width: 40px;
	border-radius: 100px;
	object-fit: cover;
}
.onhover{
	cursor: pointer;
	transition: 300ms ease-in-out;
}
.onhover:hover{
	box-shadow: 1px 1px 12px -2px white;
}