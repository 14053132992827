.slider_main_x{
	width: 100%;
	height: auto;
	background-color: black;
	color: white;
}
.star{
	height: auto;
	width: 20px;
	padding:0px 40px 0 60px;
}
.item{
	height: 25px;
	width: auto;
}
