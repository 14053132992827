
.bg{
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: #000000c7;
	z-index: -1;
	opacity: 0;
	transition: 300ms ease-in-out;
}

.quit{
	margin-left: auto;
	padding-right: 45px;
	font-size: 3rem;
	color: white;
	transform: rotate(45deg);
	cursor: pointer;
}

.quit:hover{
	color:var(--yellow-color);
}

.appear{
	z-index: 5;
	opacity: 1;
}