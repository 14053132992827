.about_mascot{
	height: auto;
	width: 450px;
}

.about_text_con{
	max-width: 450px;
	width: 100%;
	background-image: linear-gradient(to left, white , transparent);
	overflow: hidden;
	padding: 30px;
	border-radius: 8px;
	border-width: 1px;
	 border-style: solid;
  border-image: linear-gradient(to right, var(--yellow-color), transparent) 1;
  border-radius: 15px;
}
.about_text{
	font-size: 1.2rem;
}

.img_container_about{
	position: relative;
}
.shadow{
	position: absolute;
	top: 0;
	left: 10px;
	z-index: -1;
	transition: 300ms ease-in-out;
}
.shadow-hover{
	left: 0;
}

.about_container{
	width: 100%;
	margin-top: 50px;
}

@media (min-width:600px) and (max-width:800px){
	.about_main{
		flex-direction: column;
	}
	.about_text_con{
		max-width: 400px;
	}
}

@media screen and (max-width:1100px){
	.about_mascot{
		width: 221px;
	}
}

@media screen and (max-width:600px){
	.about_text_con{
		max-width: 350px;
	}
	.about_main{
		flex-direction: column;
	}
}