.Floating_bar{
	position: fixed;
	bottom: 20px;
	left: 50%;
	transform: translate(-50%);
	background: #fbf4ce59;
	border-radius: 50px;
	padding: 5px;
	backdrop-filter:saturate(2.2) blur(16px);
	border:1px solid var(--yellow-color);
	gap: 20px;
}

.icons{
	padding: 13px;
	color: var(--yellow-color);
	cursor: pointer;
	border-radius: 50px;
}

.icons:hover{
	background-color: #f4e6828a;
}

.active{
	background-color: #f4e6828a;
}