.app{
 
}

.flex_app{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px
}

.column{
  flex-direction: column;
}

.full{
  width: 100%;
  height: 100%;
}

.full-v{
  width: 100vw;
  height: ;
}

.text{
  margin: 0;
  font-family: mid, Arial, serif;
}

.animation{
  transition: 250ms ease-in-out;
  cursor: pointer;
}

.wrap{
  flex-wrap: wrap;
}

.pointer{
  cursor: pointer;
}

@font-face {
  font-family: 'bold';
  font-weight: 900;
  src: local('bold'), url(./Assets/font/bold.otf) format('opentype');
}
@font-face {
  font-family: 'light';
  font-weight: 900;
  src: local('light'), url(./Assets/font/light.otf) format('opentype');
}
@font-face {
  font-family: 'Reg';
  font-weight: 900;
  src: local('Reg'), url(./Assets/font/reg.otf) format('opentype');
}
@font-face {
  font-family: 'mid';
  font-weight: 900;
  src: local('mid'), url(./Assets/font/mid.otf) format('opentype');
}

.Bold {
    font-family: bold, Arial, serif;
    font-weight: bold;
}
.Light{
    font-family: bold, Arial, serif;
}
.Reg{
    font-family: Reg, Arial, serif !import;
}
.Mid{
    font-family: mid, Arial, serif;
}

.noa{
  text-decoration: none;
  color: black;
  transition: 300ms ease-in-out;
}

.noa:hover{
  color: var(--yellow-color);
}