.gallery_main{
	height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap:50px;
	overflow: hidden;
}


.container_carrousel{
	max-width: 1250px;
    width: 100%;
}

.img_container{
	height: auto;
	width: 370px;
	transition: 300ms ease-in-out;
}

.img{
	height: 100%;
	width: 100%;
	object-fit: cover;
	user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.img_slider_container{
	height: auto;
	width: 290px;
	transition: 300ms ease-in-out;
	padding: 0px 5px;
	overflow: hidden;
}

@media screen and (max-width:1240px){
	.img_container{
		width: calc(100% - 20px) !important;
		padding: 0 10px;
	}
	.gallery_main{
		margin-top: 30px;
		height: auto;
	}
}

.title{
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px
}

.head_title{
	font-size: 30px;
	overflow: hidden;
}

.arrow{
	transform: rotate(180deg);
	color: var(--yellow-color);
	-webkit-text-stroke: 1px black;
}