.home{
	height: calc(100vh - 81px);
	width: 100%;
	position: relative;
	overflow: hidden;
}

.container{
	max-width: 1250px;
	width: 100%;
	height: 100%;
	padding: 0 20px;
}

.logo{
	height: auto;
	width: 450px;
	z-index: 3;
	transition: 800ms ease-in-out;
}


.hello{
	height: auto;
	width: 25px;
	margin-bottom: -7px;

}
.intro{
	max-width: 500px;
	width: 100%;
	align-items: flex-start;
	margin-right: auto;
}

.big{
	font-size: 1.8rem;
	padding-bottom: 15px;
}

.highlight{
	color: var(--yellow-color);
	font-family: bold, Arial, serif !important;
    font-weight: bold !important;
}

.head{
	font-size: 2rem;
	overflow: hidden;
}

.button{
	padding:13px 40px;
	color: black;
	border:1px solid black;
	border-radius: 10px;
	cursor: pointer;
	background: linear-gradient(to left, transparent 50%, var(--yellow-color) 50%) right;
	background-size: 200% 100%;
	transition:all 550ms ease;
	font-weight: bold;
	font-size: 1.2rem !important;
}
.button:hover{
	 background-position: left;
	 color:black;
}

@media screen and (max-width:1100px){
	.container{
		flex-direction: column-reverse;	
	}

	.intro{
		margin-right: 1px;
		align-items: center;
		text-align: center;
	}
	.home{
		height: auto;
		padding: 20px 0;
	}

	.logo{
		width: 221px;
	}
}

.mascot_container{
	position: relative;
}
.mascot_shadow_down{
	position: absolute;
	height: 20px;
	width: 50%;
	background: #00000054;
	bottom: 0;
	left: 50%;
	transform:translateX(-50%);
	border-radius: 125% 125% 80% 80%;	
}
.stop{
	position: unset;
	transform: translateX(0);
}