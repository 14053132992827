header{
	border-bottom: 1px solid black;
	width: 100%;
	padding: 15px;
}

.header_main{
	max-width: 1250px;
	margin:0 20px;
	width: 100%;

}
.menu{
	margin-left: auto;
	font-size: 2.2rem;
}

.name:hover{
	color:var(--yellow-color);
}
.menu:hover{
	color: var(--yellow-color);
}
.header_logo{
	height: auto;
	width: 40px;
}
.holder{
	margin-left: auto;
}
.icon_holder{
	transition: 300ms ease-in-out;
	opacity: 0;
	z-index: -1;
}
.icon_holder_show{
	opacity: 1;
	z-index: 1;
}

.header_icon_holder{
	color: black;
}
.header-icons{
	font-size: 1.3rem;
	transition: 300ms ease-in-out;
}
.header-icons:hover{
	color: var(--yellow-color);
}

@media screen and (max-width:1100px){
	.tabs{
		display: none;
	}
}

.tabs{
	width: 70%;
}
.tab{
	width: 20%;
	text-align: center;
}