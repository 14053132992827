.footer{
	width: 100%;
	height: auto;
	position: relative;
	background-color: #1a142e;
	padding: 30px 0;
	overflow: hidden;
}


.footer-icons{
	font-size: 1.1rem;	
}

.footer_icon_holder{
	background-color: white;
	padding: 10px 11px 7px 11px;
	color:black;
	border-radius: 10px;
	cursor: pointer;
	transition: 300ms ease-in-out;
}

.footer_icon_holder:hover{
	background-color: var(--yellow-color);
}

.bar{
	height: 1px;
	width: 70px;
	background-color: white;
	margin: 8px 0;
}

.copyright{
	color: white;
}

.squar {
  width: auto;
  height: auto;
  margin: 15px;
  background: white;
  border-radius: 10px;
  overflow: hidden;
  padding: 0;
}
.icons-container{
	overflow: hidden;
	gap: 0px;
}

.emails{
	color: white;
	margin-left: auto;
	flex-wrap: wrap;
}

.dot{
	height: 3px;
	width: 3px;
	border-radius: 5px;
	background-color: white;
}

.info{
	font-size: 0.9rem;
	text-decoration: none;
	color: white;
	font-weight: bold;
	transition: 300ms ease-in-out;
}

.info:hover{
	color: var(--yellow-color) !important;
}

.heart{
	color: red;
	font-size: 1.3rem;
}

.dev{
	gap:5px;
	text-decoration: none;
}
.copy{

	width: calc(100vw - 40px);
	padding: 0 10px;
 
}

@media screen and (max-width:900px){
	.copy{
		flex-direction: column;
	}
	.emails{
		margin-left: unset;
	}
	.copyright{
	}
}

.logos_container{
	gap:20px;
	max-width: 90%;
	width: 100%;
	flex-wrap: wrap;
	margin-bottom: 50px;
}

.logos{
	height: auto;
	width: 100px;
	cursor: pointer;
}